<template>
<div style="padding: 10px">
  sadfsadfsadsadfasdfasdfasdf
</div>
</template>

<script>
export default {
  name: "Gzhpapersubmitdetail"
}
</script>

<style scoped>

</style>